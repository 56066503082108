<template>
  <section class="bando-head">
    <h1>Bienvenue sur l'interface de prise de rendez-vous FSE</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Connectez-vous :</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <input
            v-model="username"
            type="text"
            class="form-control"
            name="email"
            placeholder="Adresse e-mail"
          />
        </div>
        <div class="form-group">
          <input
            v-model="password"
            type="password"
            class="form-control"
            id="password-log"
            name="password"
            placeholder="Mot de passe"
          />
          <span toggle="#password-field" class="toggle-password"></span>

          <div class="error" v-if="error">
            L'identifiant et/ou le mot de passe sont incorrects. Réessayez ou
            cliquez sur "Mot de passe oublié" pour le réinitialiser.
          </div>
          <!-- <p class="mt-3 text-right"><a href="">Mot de passe oublié</a></p> -->
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="connected"
            />
            <label class="custom-control-label" for="connected"
              >Rester connecté</label
            >
          </div>
        </div>
        <div class="button">
          <button type="submit" class="btn btn-primary">Se connecter</button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "../store/actions/auth";

const $ = window.$;

export default {
  name: "Connexion",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      error: false,
      loading: false
    };
  },
  mounted() {
    document.body.classList.add("connexion");

    $(".toggle-password").click(function() {
      var input = $("#password-log");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
      $(this).toggleClass("hide");
    });
  },
  unmounted() {
    document.body.classList.remove("connexion");
  },
  methods: {
    login: function() {
      this.loading = true;
      this.error = false;

      const { username, password } = this;
      this.$store
        .dispatch(AUTH_REQUEST, { username, password })
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 6rem;
}

.content {
  margin: 0 auto;
  padding-bottom: 8rem;
  width: 33rem;

  @media screen and (min-width: 992px) {
    padding-bottom: 18rem;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
  }

  .toggle-password {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;

    &:before {
      content: "\f06e";
      font-family: "Font Awesome 5 Pro";
      font-size: 1.6rem;
      font-weight: 300;
    }

    &.hide {
      &:before {
        content: "\f070";
      }
    }
  }

  .button {
    margin-top: 6rem;
    text-align: center;

    .btn {
      font-size: 1.8rem;
      padding: 1.25rem 5rem;
    }
  }
}
</style>
